.integrations {
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.integration_header {
    margin-bottom: 20px;
}

.integration_header h1 {
    margin-bottom: 7px;
}

.integration_header p {
    color: rgba(0, 0, 0, 0.532);
}

.integration_item_list {
    max-height: 800px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow: auto;
}

.integration_item {
    border: 1px solid rgba(0, 0, 0, 0.122);
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 40px;
    padding: 20px;
}

.responsive_logo_button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.integration_item_info {
    display: flex;
    align-items: center;
    gap: 15px;
}

.logo {
    width: 60px;
    height: 60px;
    border: 1px solid rgba(0, 0, 0, 0.122);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.info p {
    color: rgba(0, 0, 0, 0.532);
}

.connect_button button {
    border-radius: 8px;
    padding: 10px 20px;
    background-color: #FFAAA5;
    color: white;
}

.connect_button button:disabled {
    background-color: lightgray;
}

/* Redirection from stripe route */
.stripe_return_url,
.stripe_refresh_url {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.success_try_again_message {
    text-align: center;
}
.success_try_again_message p {
    margin-bottom: 10px;
    font-size: 1.1rem;
    font-weight: bold;
}

.success_try_again_message button {
    background-color: #FFAAA5;
    color: white;
    padding: 7px 20px;
    border-radius: 5px;
}

.success_try_again_message button:disabled {
    background-color: lightgray;
}