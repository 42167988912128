.loading_error {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.loading_error button {
    background-color: white;
    border: 1px solid #FFAAA5;
    border-radius: 5px;
    padding: 5px 20px;
}