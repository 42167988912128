.reset_password {
    width: 100%;
    display: flex;
    background-color: white;
}

.password_info {
    width: 50%;
    padding: 5%;
}

.password_info > * {
    color: #67748E;
}

.password_info h2, 
.password_info p {
    text-align: center;
}

.password_info h2 {
    margin-bottom: 30px;
}

.password_form {
    width: 70%;
    margin: auto;
}

.forgot_password_section {
    width: 70%;
    margin: auto;
}


@media(max-width: 1000px) {

    .login {
        padding: 2%;
    }

    .password_info {
        width: 100%;
    }

    .password_form {
        width: 100%;
    }

}