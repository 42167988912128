.sell_product {
    display: flex;
    gap: 25px;
    align-items: center;
    padding: 40px;
}

.sell_product > * {
    width: 50%;
    height: 100%;
}

.product_image img {
    width: 100%;
    height: 100%;
    vertical-align: bottom;
}

.sale_info h2 {
    margin-bottom: 20px;
    color: #FFAAA5;
}

p.product_description {
    margin-bottom: 20px;
}

.price {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.discounted_price {
    color: #FFAAA5;
}

.save_text {
    font-size: 0.9rem;
}

.quantity {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

@media(max-width: 1000px) {

    .sell_product {
        padding: 20px;
    }

    .product_image {
       display: none;
    }

    .sale_info {
        width: 100%;
    }
    
}
