.calendar_route {
    height: calc(100vh - 110px);
}

.calendar {
    position: relative;
    height: 92%;
    padding: 0px 10px;
}

.search_select_location {
    height: 8%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.cal_horizontal_line {
    height: 1px;
    background-color: red;
    position: absolute;
    left: 0;
    z-index: 1;
}

.main_calendar {
    height: 100%;
    position: relative;
    display: flex;
    overflow: auto;
}

.hours_container {
    position: sticky;
    left: 0;
    width: 120px;
    height: 1750px;
    border: 1px solid rgba(0, 0, 0, 0.222);
    z-index: 1;
}

.hour, .hour_blank {
    border-bottom: 1px solid rgba(0, 0, 0, 0.222);
    text-align: center;
    padding-top: 5px;
    user-select: none;
    -webkit-user-select: none;
    background-color: white;
}

.hour {
    height: 100px;
}

.hour_blank {
    height: 50px;
    border-top: 1px solid rgba(0, 0, 0, 0.222);
    top: 0;
    left: 0;
    position: sticky;
}

.day_view {
    position: relative;
    display: flex;
    min-width: 100%;
}

.week_view {
    position: relative;
    width: 100%;
    display: flex;
}

.day_times_container {
    min-width: 25%;
    height: 1750px;
    border-right: 1px solid rgba(0, 0, 0, 0.567);
}

.week_times_container {
    height: 1750px;
    min-width: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.567);
}

.week_events_container {
    width: 100%;
    display: flex;
}

.week_events {
    width: 14.2857%;
    border-right: 1px solid rgba(0, 0, 0, 0.222);
}

.month_times_container {
    min-width: 100%;
}

.stylist_name {
    background-color: white;
    position: sticky;
    top: 0px;
    z-index: 1;
    width: 100%;
    height: 50px;
    border-top: 1px solid rgba(0, 0, 0, 0.222);
    border-bottom: 1px solid rgba(0, 0, 0, 0.222);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.week_stylist_name_container {
    background-color: white;
    width: 100%; 
    height: 50px; 
    border: 1px solid rgba(0, 0, 0, 0.222);
    border-left: none;
    position: sticky;
    top: 0px;
    z-index: 1;
}

.week_stylist_name {
    width: 100%;
    height: 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.222);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
}

.week_dates_container {
    width: 100%;
    display: flex;
}

.week_date {
    width: 14.2857%;
    height: 25px;
    border-right: 1px solid rgba(0, 0, 0, 0.222);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 0.9rem;
}

.timeslot_groups_container {
    position: relative;
    width: 100%;
}

.events_container {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.event {
    width: 100%;
    position: absolute;
    left: 0;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 7px;
    pointer-events: auto;
    cursor: pointer;
    font-size: 0.8rem;
    overflow: hidden;
    user-select: none;
    -webkit-user-select: none;
}

.month_event {
    position: relative;
}

.event p,
.month_event p {
    padding: 2px;
}

.event p:first-child,
.month_event p:first-child {
    background-color: #538392;
    color: white;
}

.event_icons {
    position: absolute;
    top: 2px;
    right: 5px;
    display: flex;
    gap: 5px;
    z-index: 1;
}

.timeslot_group {
    height: 100px;
    background-color: rgb(238, 238, 238);
    border-bottom: 1px solid rgba(0, 0, 0, 0.222);
}

.timeslot {
    height: 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.timeslot_available {
    background-color: aliceblue;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.timeslot:nth-child(4) {
    border-bottom: none;
}

.timeslot_available:nth-child(4) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.222);
}

.month_view {
    display: flex;
    width: 100%;
}

.month_dates_container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.month_date {
    position: relative;
    width: 14.2857%;
    height: 150px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    padding: 5px;
    cursor: pointer;
}

.month_date_date {
    text-align: right;
}

.month_date span {
    display: block;
    margin-top: 10px;
    text-decoration: underline;
    color: darkblue;
    font-weight: bold;
    cursor: pointer;
}

.month_event {
    width: 100%;
    height: 20px;
    overflow: auto;
    font-size: 0.8rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    cursor: pointer;
    text-align: left;
    margin-bottom: 7px;
}

.month_event:last-child {
    margin-bottom: 0px;
}

.drag_reschedule_modal {
    padding: 40px 50px;
}

.drag_reschedule_modal button {
    display: block;
    background-color: #FFAAA5;
    border-radius: 5px;
    color: white;
    padding: 7px 20px;
    margin: 40px auto 0px auto;
}

@media(max-width: 600px) {
    
    .calendar {
        height: 85%;
    }

    .search_select_location {
        height: 15%;
        flex-direction: column;
        gap: 10px;
    }

    .week_stylist_name {
        font-size: 0.8rem;
    }

    .week_date {
        font-size: 0.7rem;
    }

}


