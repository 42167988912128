.edit_tenant_info {
    padding: 30px;
}

.edit_user_info_header {
    margin-bottom: 20px;
}

.edit_user_info_header > * {
    opacity: 0.7;
}

.edit_user_info_header h1 {
    margin-bottom: 10px;
}

.edit_user_info_header h3 {
    margin-top: 20px;
}

.flexbox_input_fields {
    display: flex;
    gap: 20px;
}

.country_dropdown {
    margin-bottom: 20px;
}

.country_dropdown label {
    display: block;
    margin-bottom: 5px;
    color: rgba(0, 0, 0, 0.53);
}

.country_dropdown label span {
    color: orangered;
}

.country_dropdown p {
    margin-top: 5px;
    font-size: 0.9rem;
    color: orangered;
}

.save_btn_wrapper {
    text-align: center;
    margin-top: 40px;
}


@media(max-width: 1000px) {

    .edit_tenant_info {
        padding: 30px 15px;
    }
    
    .flexbox_input_fields {
        display: flex;
        flex-direction: column;
        gap: 0px;
    }
    
}