.confirm_button {
    text-align: center;
    margin-top: 25px;
}

.confirm_button button {
    background-color: #FFAAA5;
    border-radius: 5px;
    padding: 7px 25px;
    color: white;
}