.emails_page {
    position: relative;
}

.add_edit_followup_email {
    padding: 40px;
    overflow: auto;
}

.add_edit_header {
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.53);
}

.add_edit_followup_email label {
    display: block;
    margin-bottom: 5px;
    color: rgba(0, 0, 0, 0.53);
}

.data_table_container {
    margin-bottom: 20px;
}

.data_table_header {
    margin-bottom: 20px;
}

.search_add {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.search_add_buttons {
    display: flex;
    gap: 20px;
}

.search_add_buttons button {
    padding: 14px 25px;
    border-radius: 5px;
    background-color:#FFAAA5;
    color: white;
}

.data_table_dropdown_container {
    display: flex;
    justify-content: flex-end;
}

.data_table {
    height: 600px;
    border-radius: 5px;
    overflow: auto;
}

.data_table table {
    width: 100%;
    border-collapse: collapse;
}

.heading_tr {
    position: sticky;
    top: -20px;
    background-color: white;
    z-index: 1;
}

.data_table table th {
    text-align: left;
    color: #809FB8;
    font-size: 1.1rem;
    padding: 15px 0px;
}

.data_tr td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.113);
    padding: 15px 0px;
    font-size: 1.1rem;
}

.table_message_tr {
    height: 538px;
    text-align: center;
    border-bottom: none;
}

.action_buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.action_buttons button {
    margin-right: 20px;
    background: none;
}

.action_buttons button:last-child {
    margin-right: 0px;
}

.responsive_item {
    border: 1px solid rgba(0, 0, 0, 0.103);
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 10px;
    margin-bottom: 20px;
}

.responsive_item_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.103);
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.responsive_item_name {
    color: rgba(0, 0, 255, 0.552);
}

.responsive_other_info p {
    margin-bottom: 5px;
}

.next_prev_view {
    background-color: #809FB8;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    padding: 1%;
}

.next_prev_buttons button {
    background-color: #344C64;
    border: none;
    border-radius: 20px;
    color: white;
    cursor: pointer;
    padding: 5px 10px;
}

.next_prev_buttons button:first-child { 
    margin-right: 20px; 
}

.item_count p {
    color: white;
}


/* Redirection from mailchimp route */
.mailchimp_code {
    width: 100%;
    height: 100%;
    /* background-color: #FFAAA5; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.mailchimp_code_success {
    text-align: center;
}
.mailchimp_code_success p {
    margin-bottom: 10px;
}

.mailchimp_code_success button {
    background-color: #FFAAA5;
    color: white;
    padding: 7px 20px;
    border-radius: 5px;
}

@media(max-width: 1000px) {

    .add_edit_followup_email {
        padding: 20px;
    }

    .data_table_header {
        flex-direction: column;
        gap: 20px;
    }

    .search_add {
        display: flex;
        flex-direction: column-reverse;
        gap: 20px;
    }

    .search_add_buttons {
        flex-direction: column;
    }
    
    .search_add button {
        width: 348px;
    }
    
}