button {
    border: none;
    cursor: pointer;
}

.signup_button {
    background-image: linear-gradient(to right, #67DAF8, #0844CC);
    border-radius: 20px;
    color: white;
    padding: 7px 50px;
}

.modal_cls_btn {
    background: none;
    position: absolute;
    top: 3%;
    right: 5%;
    z-index: 1;
    font-size: 2rem;
    color: orange;
}

.veify_popup_btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 14px 25px;
    background-color: #FFAAA5;
    border-radius: 5px;
    color: white;
}

.update_info_btn {
    padding: 14px 25px;
    background-color: #FFAAA5;
    border-radius: 5px;
    color: white;
}

.sub_cancel_btn {
    padding: 8px 50px;
    background-color: white;
    border: 1px solid #FFAAA5;
    border-radius: 5px;
    color: #FFAAA5;
    font-size: 1rem;
}

.success_message_close_btn {
    display: block;
    background-color: #FFAAA5;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    margin-top: 20px;
}

