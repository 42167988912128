/* Input field with label and error message */
.input_field { 
    width: 100%; 
    margin-bottom: 20px;
}
.input_field label,
.textarea_input_field label { 
    display: block;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.53);
}
.input_field .input { margin: 5px 0px 5px 0px; }

.input_field .input input[type=text] {
    background-color: #FFFFFF;
    width: 100%;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.222);
    border-radius: 6px;
    font-size: 1.1rem;
    color: rgba(0, 0, 0, 0.53);
}

.input_field .input input:focus {
    outline: none;
    border: 1px solid #A8DDE6;
}

.input_field p {
    color: #FF6363;
    font-size: 0.9rem;
}

.checkbox_input_field {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5px;
    font-size: 0.9rem;
}

.checkbox_input_field input[type=checkbox] {
    margin-right: 10px;
}



/* For Search Input */
.search_input {
    width: 350px;
    margin-bottom: 0px;
}

.search_input .input {
    margin-bottom: 0px;
}

.search_input .input input[type=text] {
    border: none;
    border-radius: 10px;
    padding: 15px;
    filter: drop-shadow(0px 20px 70px rgba(86, 89, 146, 0.1));
    font-size: 0.9rem;
}

.select_treatment_input {
    margin-bottom: 0px;
    width: 80px;
}

.select_treatment_input .input input[type=text] {
    width: 80px;
    padding: 5px 10px;
    text-align: center;
}


/* TextAreaInputField styles */
.textarea_input_field {
    width: 100%;
    margin-bottom: 20px;
}

.textarea_input_field textarea {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.222);
    border-radius: 4px;
    margin-bottom: 5px;
    padding: 10px;
    font-size: 1.1rem;
    color: rgba(0, 0, 0, 0.53);
}

.textarea_input_field textarea:focus {
    outline: none;
}

.textarea_validation_error_message {
    font-size: 0.87rem;
    color: #FF6363;
}

@media(max-width: 1200px) {
    .search_input {
        margin-bottom: 20px;
    }
}















