button {
    border: none;
    cursor: pointer;
}

.signup_button {
    background-image: linear-gradient(to right, #67DAF8, #0844CC);
    border-radius: 20px;
    color: white;
    padding: 7px 50px;
}

.modal_cls_btn {
    background: none;
    position: absolute;
    top: 3%;
    right: 4%;
    font-size: 1.5rem;
    color: #FFAAA5;
}

.add_btn, .save_btn {
    background-color: #FFAAA5;
    border-radius: 6px;
    color: white;
    font-size: 1rem;
}

.add_btn {
    padding: 14px 25px;
}

.save_btn {
    padding: 14px 50px;
}



.update_info_btn {
    padding: 14px 25px;
    background-color: #FFAAA5;
    border-radius: 5px;
    color: white;
}

.icon_button {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media(max-width: 1200px) {
    .add_btn {
        display: inline-block;
        width: 100%;
    }
}


