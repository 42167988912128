.sidenav {
    position: fixed;
    top: 0;
    left: 0;
    width: 200px;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 20px 10px;
    overflow: auto;
}

.sidenav a {
    border-radius: 8px;
    text-decoration: none;
    margin-bottom: 25px;
    color: black;
    opacity: 0.4;
}

.sidenav a:last-child {
    margin-bottom: 0px;
}

.nav_item_list {
    margin-bottom: 30px;
}

.snv_item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.snv_item_icon {
    margin-right: 10px;
}

.responsive_nav_item {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid rgba(0, 0, 0, 0.146);
    border-radius: 10px;
}

.App_Content {
    position: fixed;
    left: 220px;
    top: 0;
    display: flex;
    flex-direction: column;
    width: calc(100vw - 220px);
    height: calc(100vh - 20px);
    padding: 10px 20px;
    overflow: auto;
}

@media(max-width: 1200px) {
    .App_Content {
        left: 0;
        width: 100%;
    }
    .sidenav {
        width: 100%;
    }

    .nav_item_list {
        margin-bottom: 0px;
    }

    .today_date {
        margin-bottom: 30px;
        font-size: 0.85rem;
    }
    
}