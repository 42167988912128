.icon_button {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon_button:disabled {
    color: lightgray;
}

.save_button {
    text-align: center;
    margin-top: 30px;
}

.save_button button {
    padding: 7px 50px;
    border-radius: 5px;
    background-color:#FFAAA5;
    color: white;
}

.save_button button:disabled {
    background-color: gray;
}
