/* Input field with label and error message */
.input_field { 
    width: 100%; 
    margin-bottom: 20px;
}

.input_field .input { margin: 5px 0px 5px 0px; }

.input_field .input input[type=text], 
.input_field .input input[type=number], 
.input_field .input input[type=email], 
.input_field .input input[type=password]   {
    width: 100%;
    padding: 14px 10px;
    border: 1px solid rgba(0, 0, 0, 0.222);
    border-radius: 6px;
    outline: none;
}

.input_field p {
    color: #FF6363;
    font-size: 0.9rem;
}
