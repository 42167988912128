.dropdown_list {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid white;
    border-radius: 4px;
    cursor: pointer;
    background-color: #FFFF;
    border: 1px solid rgba(0, 0, 0, 0.13); 
}

.hidden_div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: aquamarine;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
}

.dropdown_list p {
    color: rgba(0, 0, 0, 0.53);
    font-size: 1.1rem;
}

.dropdown_item_list {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #FFFF;
    border: 1px solid rgba(0, 0, 0, 0.13);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 10px 0px;
    border-radius: 4px;
    z-index: 10;
}

.dropdown_item_list ul {
    width: 100%;
    position: relative;
    list-style-type: none;
}

.dropdown_item_list ul li {
    cursor: pointer;
    padding: 10px;
    color: rgba(0, 0, 0, 0.53);
    font-size: 1.1rem;
}

.dropdown_item_list ul li:hover {
    background-color: #A8DDE6;
}


.view_dropdown_list {
    width: 120px;
    border-radius: 10px;
    padding: 5px 10px;
}

.payment_type_dropdown_list {
    padding: 10px;
}

@media(max-width: 1200px) {
    .dropdown_list {
        width: 100%;
    }
}

