.discount_table {
    min-height: 600px;
    border-radius: 5px;
    overflow: auto;
}

.discount_table table {
    width: 100%;
    border-collapse: collapse;
}

.heading_tr {
    position: sticky;
    top: -20px;
    background-color: white;
    z-index: 1;
}

.heading_tr th {
    padding: 10px 0px;
    text-align: left;
    font-size: 0.9rem;
}

.data_tr td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.113);
    padding: 10px 0px;
    font-size: 0.9rem;
}

.table_message_tr {
    height: 538px;
    text-align: center;
    border-bottom: none;
}

.responsive_item {
    border: 1px solid rgba(0, 0, 0, 0.103);
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 10px;
    margin-bottom: 20px;
}

.responsive_item_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.103);
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.responsive_item_name {
    color: rgba(0, 0, 255, 0.552);
}

.responsive_other_info p {
    margin-bottom: 5px;
}
