.user_info {
    width: 67%;
    margin-right: 3%;
    min-height: 220px;
    background-color: white;
    filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.12));
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
}

.info p {
    margin-bottom: 10px;
}

.info_item {
    display: flex;
    align-items: center;
}

.info_header {
    color: #67748E;
    margin-right: 20px;
}

.info_content {
    color: #252F40;
}


.company_name {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.company_name h3 {
    margin-right: 10px;
    color: #67748E;
}

.company_name button {
    display: inline-block;
    background: none;
    position: relative;
}

.edit_button_container {
    position: relative;
}

.edit_button_container span {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 0.7rem;
    opacity: 0.5;
}

.edit_button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 1;
}

.edit_icon {
    font-size: 1.5rem;
    margin-bottom: 0px;
    color: #FFAAA5;
    margin-bottom: 8px;
}

.design_image {
    background-image: linear-gradient(135deg, #C8E9EF 0%, #FFFFFF 100%);
    padding: 10px;
    border-radius: 10px;
}

@media(max-width: 1200px) {
    .user_info {
        flex-direction: column-reverse;
        width: 100%;
        padding: 20px;
    }

    .user_info > * {
        width: 100%;
    }

    .design_image {
        margin-bottom: 20px;
    }
}

