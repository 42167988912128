.data_table_container {
    margin-bottom: 20px;
}

.data_table_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.data_table_buttons {
    display: flex;
    align-items: center;
    gap: 30px;
    margin: 25px 0px;
    overflow: auto;
}

.data_table_buttons button {
    background: none;
    font-size: 1.1rem;
    padding: 10px 0px;
}

.data_table {
    height: 600px;
    border-radius: 5px;
    overflow: auto;
}

.data_table table {
    width: 100%;
    border-collapse: collapse;
}

.heading_tr {
    position: sticky;
    top: -20px;
    background-color: white;
    z-index: 1;
}

.data_table table th {
    text-align: left;
    color: #809FB8;
    font-size: 1.1rem;
    padding: 15px 0px;
}

.data_tr td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.113);
    padding: 15px 0px;
    font-size: 1.1rem;
}

.plus_button_td {
    text-align: center;
}

.plus_button_td button {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
}

.table_message_tr {
    height: 538px;
    text-align: center;
    border-bottom: none;
}

.responsive_item {
    border: 1px solid rgba(0, 0, 0, 0.103);
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 10px;
    margin-bottom: 20px;
}

.responsive_item_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.103);
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.responsive_item_name {
    color: rgba(0, 0, 255, 0.552);
}

.responsive_other_info p {
    margin-bottom: 5px;
}

.next_prev_view {
    background-color: #809FB8;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    padding: 1%;
}

.next_prev_buttons button {
    background-color: #344C64;
    border: none;
    border-radius: 20px;
    color: white;
    cursor: pointer;
    padding: 5px 10px;
}

.next_prev_buttons button:first-child { 
    margin-right: 20px; 
}

.item_count p {
    color: white;
}

.receive_po_products {
    padding: 40px;
}

.receive_po_products h2 {
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.53);
}

.receive_po_products_table table {
    width: 100%;
    border-collapse: collapse;
}

.receive_po_products_table table th {
    text-align: left;
    color: #809FB8;
    font-size: 1.1rem;
    padding: 10px 0px;
}

.receive_po_products_table table td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.113);
    padding: 10px 0px;
    font-size: 0.9rem;
}

.product_sales_details {
    padding: 40px;
    overflow: auto;
}

.product_sales_details_content {
    margin-bottom: 20px;
}

.product_sales_item_info {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.136);
    padding: 10px 0px;
}

.product_sales_item_info p {
    white-space: pre-wrap;
    word-break: break-all;
}

.product_sales_item_info p:first-child {
    width: 42%;
}

.product_sales_item_info p:last-child {
    width: 58%;
}


@media(max-width: 1000px) {

    .data_table_header {
        flex-direction: column;
        gap: 20px;
    }

    .receive_po_products {
        padding: 20px 10px;
    }

    .receive_po_products_table table th {
        font-size: 0.8rem;
    }

    .receive_po_products_table table td {
        padding: 10px 5px;
        font-size: 0.8rem;
    }

}
