.signup {
    width: 100%;
    display: flex;
    background-color: white;
    max-height: 1000px;
}

.user_credentials {
    width: 50%;
    height: 100%;
    overflow: auto;
    padding: 5%;
}

.user_credentials {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.user_credentials > * {
    color: #67748E;
}

.user_credentials h2 {
    text-align: center;
    margin-bottom: 30px;
}

.info {
    background-color: aliceblue;
}

.signup_form {
    width: 70%;
    margin: auto;
}

.signup_success_msg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.signup_success_msg a {
    width: 70px;
    background-color: #FFAAA5;
    border-radius: 5px;
    color: white;
    padding: 5px 0px;
    text-decoration: none;
    text-align: center;
}

.have_account {
    width: 70%;
    margin: 30px auto 0px auto;
    display: flex;
    justify-content: center;
    gap: 5px;
}

.have_account > * {
    font-size: 0.85rem;
}

.have_account a {
    text-decoration: none;
    color: #A8DDE6;
}

@media(max-width: 1000px) {

    .signup {
        padding: 2%;
    }

    .user_credentials {
        width: 100%;
        margin-bottom: 20px;
    }

    .signup_form {
        width: 100%;
    }
    
    .have_account {
        width: 100%;
        margin: 30px auto 30px auto;
    }
}