.client_search { 
    width: 300px;
    position: relative; 
}

.client_name_list {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 3;
    width: 100%;
    min-height: 200px;
    max-height: 400px;
    overflow: auto;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    padding: 5px;
}

.client_name_list ul {
    list-style: none;
    padding: 5px;
}

.client_name_list ul li {
    background-color: white;
    border-radius: 4px;
    padding: 7px 5px;
    margin-bottom: 10px;
    transition: 0.2s;
    cursor: pointer;
}

.client_name_list ul li:last-child {
    margin-bottom: 0px;
}

.client_name_list ul li:hover {
    background-color: #E4E0E1;
}

/* Client Details Modal */
.client_details_modal {
    padding: 40px 30px;
}

.client_details {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.client_main_info {
    width: 50%;
    margin-right: 5%;
}

.client_private_note {
    width: 45%;
    background-color: #AEBDCA;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2%;
    font-size: 0.9rem;
}

.client_main_info h3 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.208);
    padding-bottom: 5px;
}

.client_main_info h3 a {
    text-decoration: none;
}

.client_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.208);
    padding: 8px 0px;
    font-size: 0.85rem;
}

.appointment_table table {
    width: 100%;
    border-collapse: collapse;
}

.appointment_table tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.213);
}

.appointment_table th {
    padding-bottom: 5px;
    font-size: 0.9rem;
}

.td_table_row { cursor: pointer; }
.td_table_row:hover { background-color: #EEEEEE; }

.appointment_table table td {
    position: relative;
    text-align: center;
    padding: 7px 0px;
    font-size: 0.75rem;
    min-width: 50px;
}

.buttons {
    text-align: center;
    margin-bottom: 20px;
}

.buttons button {
    background-image: linear-gradient(to right, #67DAF8, #0844CC);
    border: none;
    border-radius: 20px;
    color: white;
    cursor: pointer;
    padding: 7px 30px;
    margin-right: 30px;
}

.buttons button:last-child { margin-right: 0px; }


@media(max-width: 1000px) {
    .client_search {
        width: 100%;
    }
    .client_details { display: block; }
    .client_main_info { 
        width: 100%;
        margin-bottom: 30px; 
    }
    .client_private_note { width: 100%; }
    .client_info { font-size: 0.7rem; }
    .appointment_table table td { font-size: 0.65rem; }
    .buttons button {
        margin-right: 0px;
        margin-bottom: 20px;
    }
    .buttons button:last-child { margin-bottom: 0px; }
}
