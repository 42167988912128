.report_overview {
    width: 100%;
    display: flex;
    gap: 25px;
    align-items: center;
    margin-bottom: 30px;
}

.overview_card {
    width: 25%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    color: rgba(0, 0, 0, 0.5);
}

.overview_card:nth-child(odd) {
    border: 2px solid #A8DDE6;
}

.overview_card:nth-child(even) {
    border: 2px solid #FFAAA5;
}

.overview_card_icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filters {
    display: flex;
    gap: 20px;
}

.filter_button {
    background-color: #FFAAA5;
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
}

.select_date input[type=text] {
    width: 150px;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.187);
    border-radius: 5px;
}

.select_date input[type=text]:focus {
    outline: none;
}

.table_buttons_dropdown {
    padding: 0px 5px;
    margin-top: 25px;
    margin-bottom: 40px;
}

.table_buttons {
    display: flex;
    align-items: center;
    gap: 30px;
    margin: 25px 0px;
    overflow: auto;
}

.table_buttons button {
    background: none;
    font-size: 1.1rem;
    padding: 10px 0px;
}

.data_table {
    width: 100%;
    padding: 20px;
    border: 2px solid rgba(0, 0, 0, 0.136);
    border-radius: 10px;
}

.export_csv_btn {
    text-align: right;
    margin-bottom: 10px;
    padding-right: 40px;
}

.export_csv_btn button {
    background-color: #FFAAA5;
    border-radius: 10px;
    color: white;
    padding: 7px 15px;
}

.table_container {
    width: 100%;
    height: 500px;
    overflow: auto;
    padding: 0px 20px;
}

.table_container table {
    width: 100%;
    border-collapse: collapse;
    
}

.table_container table th {
    padding: 10px;
    color: #809FB8;
    font-size: 0.9rem;
}

.table_container table td {
    padding: 30px 10px 10px 10px;
    font-size: 0.8rem;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.136);
    color: #050708;
}

.category {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.category_icon {
    background-color: #A8DDE6;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 4px;
}

.category_icon img {
    width: 24px;
    height: 24px;
}

.category span {
    margin-left: 10px;
}

.package_search_export {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.client_pkg_details {
    padding: 40px;
    overflow: auto;
}

.pkg_item_info {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.136);
    padding: 10px 0px;
}

.pkg_item_info p {
    white-space: pre-wrap;
    word-break: break-all;
}

.pkg_item_info p:first-child {
    width: 42%;
}

.pkg_item_info p:last-child {
    width: 58%;
}

.pkg_treatment_list {
    margin: 30px 0px;
}

.pkg_treatment_list h3,
.pkg_payment_list h3 {
    text-align: center;
}

.pkg_pay_button {
    background-color: #1C325B;
    border-radius: 5px;
    color: white;
    padding: 2px 15px;
}

.pkg_method_input input[type=text] {
    width: 120px;
    border: 1px solid rgba(0, 0, 0, 0.226);
    border-radius: 5px;
    padding: 3px 5px;
}

.pkg_method_input input[type=text]:focus {
    outline: none;
}

.update_pkg_payment_button {
    text-align: center;
    margin-top: 30px;
}

.update_pkg_payment_button button {
    background-color: #FFAAA5;
    border-radius: 5px;
    color: white;
    padding: 7px 50px;
}
.update_pkg_payment_button button:disabled {
    background-color: lightgray;
}

.package_payment_msg {
    text-align: center;
    color: orangered;
    margin-top: 20px;
}

.view_btn {
    background-color: white;
}


@media(max-width: 1000px) {

    .filters {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .filters > * {
        width: 45%;
    }

    .filter_button {
        width: 100%;
        margin: 15px 5px;
        padding: 10px 0px;
    }

    .report_overview {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    
    .overview_card {
        width: 45%;
        padding: 10px;
    }

    .overview_card_info {
        text-align: center;
    }
    
    .overview_card_info > * {
        white-space: pre-wrap;
    }

    .data_table {
        border: none;
        padding: 0px;
    }

    .export_csv_btn {
        padding-right: 5px;
    }

    .table_container {
        padding: 0px 5px;
    }

    .client_pkg_details {
        padding: 40px 10px;
        font-size: 0.75rem;
    }

    .pkg_method_input input[type=text] {
        width: 80px;
    }
    
}