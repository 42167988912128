.action_buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.action_buttons button,
.action_icon_button:disabled {
    background: none;
}

.action_icon_button {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon_button {
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logout_icon_btn {
    flex-direction: row;
    opacity: 0.4;
}

.logout_icon_btn span {
    margin-left: 10px;
    font-size: 0.9rem;
    color: black;
}

.download_button {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.download_btn_div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
}

.download_button span {
    font-size: 0.8rem;
    color: #E0E0E0;
}
