.select_treatment {
    height: 380px;
    overflow: auto;
    background-color: #FFFFFF;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.select_treatment h2 {
    color: rgba(0, 0, 0, 0.53);
}

.treatment_item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 10px 40px 10px 0px;
}


@media(max-width: 1000px) {
    .treatment_item {
        padding: 10px 10px 10px 0px;
    }
}
