.checkout_form {
    overflow: auto;
    padding: 40px;
}

.checkout_header {
    color: rgba(0, 0, 0, 0.53);
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.checkout_header span {
    color: #FFAAA5;
    font-weight: bold;
}

.select_price_invoice_summary {
    display: flex;
    margin-bottom: 30px;
}

.select_price_invoice_summary > * {
    width: 50%;
}

.select_price {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-right: 20px;
    border-right: 1px solid rgba(0, 0, 0, 0.156);
}

.invoice_summary {
    margin-bottom: 20px;
}

.invoice_summary, 
.vat_id {
    padding-left: 20px;
}

.invoice_amount {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    margin-bottom: 13px;
}

.invoice_amount:last-child {
    margin-bottom: 0px;
    padding-top: 5px;
    border-top: 1px solid rgba(0, 0, 0, 0.319);
    font-weight: bold;
}

.vat_id .input_container {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.177);
    border-radius: 5px;
}

.vat_error_msg {
    font-size: 0.85rem;
    color: orangered;
}

.checkbox_wrapper {
    background-color: #F6F5F5;
    border-radius: 5px;
    padding: 10px;
}

.price_disabled {
    background-color: lightgray;
    z-index: -1;
}

.checkbox_wrapper .round {
    position: relative;
    display: flex;
    align-items: center;
    gap: 15px;
}

.checkbox_wrapper .round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    width: 28px;
    display: block;
}

.checkbox_wrapper .round span {
    font-size: 1.2rem;
}

.checkbox_wrapper .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 8px;
    opacity: 0;
    position: absolute;
    top: 9px;
    transform: rotate(-45deg);
    width: 12px;
}

.checkbox_wrapper .round input[type="checkbox"] {
    margin-right: 20px;
    visibility: hidden;
    display: none;
    opacity: 0;
}

.checkbox_wrapper .round input[type="checkbox"]:checked + label {
    background-color: #FFAAA5;
    border-color: #FFAAA5;
}

.checkbox_wrapper .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
}

.credit_card {
    width: 100%;
}

.card_number_elmt {
    margin-bottom: 20px
}

.card_other_elmts {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.expiry_elmt_container {
    width: 50%;
    margin-right: 5%;
}

.cvc_elmt_container {
    width: 45%;
}


.pay_cancel_btn {
    width: 100%;
    margin-top: 50px;
    text-align: center;
}


.sub_success_msg {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sub_success_msg h3 {
    text-align: center;
}



@media(max-width: 1000px) {

    .checkout_form {
        width: 100%;
        padding: 40px 20px;
    }

    .select_price_invoice_summary {
        flex-direction: column;
        gap: 30px;
    }
    
    .select_price_invoice_summary > * {
        width: 100%;
    }

    .select_price {
        padding-right: 0px;
        border-right: none;
    }

    .invoice_summary,
    .vat_id {
        padding-left: 0px;
    }
    
}


