button {
    border: none;
    cursor: pointer;
}

.add_btn, .save_btn {
    background-color: #FFAAA5;
    border-radius: 6px;
    color: white;
    font-size: 1rem;
}

.add_btn {
    padding: 14px 25px;
}

.save_btn {
    padding: 14px 50px;
}

.icon_button {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media(max-width: 1000px) {
    .add_btn {
        display: inline-block;
        width: 100%;
    }
}


