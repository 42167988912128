.locations_page {
    position: relative;
}

.search_add_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

@media(max-width: 1200px) {
    .search_add_btn {
        flex-direction: column;
    }
}

