.card_image {
    background-image: linear-gradient(135deg, #C8E9EF 0%, #FFFFFF 100%);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    margin-bottom: 30px;
}


/* SELECT OPTIONS */
.select_options {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
}

.select_options button {
    display: block;
    width: 50%;
    border: 1px solid #A8DDE6;
    border-radius: 6px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.516);
    padding: 10px 0px;
    font-size: 1.1rem;
}

.select_options button:first-child {
    margin-right: 20px;
}




.credit_card {
    width: 100%;
}

.card_number_elmt {
    margin-bottom: 20px
}

.card_other_elmts {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.card_expiry {
    width: 50%;
    margin-right: 5%;
}

.card_cvc {
    width: 45%;
}

.sepa_element_wrapper {
    width: 100%;
}

.element_wrapper, .sepa_element_wrapper {
    border: 1px solid #A8DDE6;
    border-radius: 6px;
    padding: 15px;
    font-size: 1.1rem;
}

.element_label {
    display: block;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.53);
}

@media (max-width: 1200px) {
    .card_image img {
        width: 100%;
    }
}