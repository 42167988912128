.label { 
    display: block;
    margin-bottom: 5px;
    color: rgba(0, 0, 0, 0.53);
}

.label span {
    color: #FF6363;
}

.input_val_error_msg {
    color: #FF6363;
    font-size: 0.9rem;
}

/* Text, Email And Password Inputs */
.input_field { 
    width: 100%; 
    margin-bottom: 20px;
}

.no_margin_bottom {
    margin-bottom: 0px;
}

.input_container { 
    margin: 5px 0px 5px 0px; 
}

.input_container input[type=text],
.input_container input[type=number],
.input_container input[type=password],
.input_container input[type=email] {
    background-color: #FFFFFF;
    width: 100%;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.222);
    border-radius: 6px;
    font-size: 1.1rem;
    color: rgba(0, 0, 0, 0.53);
}

.input_container input:focus {
    outline: none;
    border: 1px solid #A8DDE6;
}

.input_container input:disabled {
    background-color: lightgrey;
}

.input_container input[type=number]::-webkit-inner-spin-button, 
.input_container input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}

.input_container input[type=number] {
  -moz-appearance: textfield;
}


/* TextAreaInputField styles */
.textarea_input_field {
    width: 100%;
    margin-bottom: 20px;
}

.textarea_input_field textarea {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.222);
    border-radius: 4px;
    margin-bottom: 5px;
    padding: 10px;
    font-size: 1.1rem;
    color: rgba(0, 0, 0, 0.53);
}

.textarea_input_field textarea:focus {
    outline: none;
}


/* File Input */
.file_input { 
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.file_input input { 
    display: none; 
}

.file_input_content {
    text-align: center;
}

.avatar_container {
    margin-bottom: 10px;
}

.avatar_container img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    vertical-align: bottom;
}

.image_preview {
    margin-bottom: 10px;
}

.image_preview img {
    width: 100px;
    height: 100px;
    vertical-align: bottom;
}

.upload_button {
    background-color: #FFAAA5;
    border-radius: 5px;
    color: white;
    padding: 5px 20px;
}


/* Checkbox Input Field */
.checkbox_input_field {
    padding: 5px;
    font-size: 0.9rem;
}

.checkbox_input_field input[type=checkbox] {
    margin-right: 10px;
}


/* Custom Checkbox Input */
.custom_checkbox_wrapper {
    background-color: #F6F5F5;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
}

.custom_checkbox_wrapper .checkbox_round {
    position: relative;
    display: flex;
    align-items: center;
    gap: 15px;
}

.custom_checkbox_wrapper .checkbox_round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    width: 28px;
    display: block;
}

.custom_checkbox_wrapper .checkbox_round span {
    font-size: 1.2rem;
}

.custom_checkbox_wrapper .checkbox_round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 8px;
    opacity: 0;
    position: absolute;
    top: 9px;
    transform: rotate(-45deg);
    width: 12px;
}

.custom_checkbox_wrapper .checkbox_round input[type="checkbox"] {
    margin-right: 20px;
    visibility: hidden;
    display: none;
    opacity: 0;
}

.custom_checkbox_wrapper .checkbox_round input[type="checkbox"]:checked + label {
    background-color: #FFAAA5;
    border-color: #FFAAA5;
}

.custom_checkbox_wrapper .checkbox_round input[type="checkbox"]:checked + label:after {
    opacity: 1;
}

/* Custom Class Names */
.no_margin_bottom {
    margin-bottom: 0px;
}

.search_input {
    width: 336px;
    margin-bottom: 0px;
}

.search_input .input_container {
    margin-bottom: 0px;
}

.search_input .input_container input[type=text] {
    border: none;
    border-radius: 10px;
    padding: 12px 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}


/* From Products Page - SellProduct And PurchaseOrder components */
.sale_product_quantity {
    width: 60px;
    margin-bottom: 0px;
}

.purchase_order_input {
    width: 100px;
    margin-bottom: 0px;
}

.sale_product_quantity .input_container input[type=text] {
    padding: 5px 0px;
    text-align: center;
}

.purchase_order_input .input_container input[type=number] {
    padding: 5px 0px;
    text-align: center;
    font-size: 0.9rem;
}


@media(max-width: 1000px) {
    
    .purchase_order_input {
        width: 70px;
    }

    .purchase_order_input .input_container input[type=number] {
        font-size: 0.7rem;
    }
    
}
