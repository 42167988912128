.design_image {
    width: 50%;
    height: 100%;
}

.design_image img {
    width: 100%;
    height: 100%;
    vertical-align: bottom;
    object-fit: cover;
}

.logo_image {
    margin: 0px auto 20px auto;
    text-align: center;
}

.logo_image img {
    width: 30%;
}

.submit_button {
    margin: 30px auto 0px auto;
    text-align: center;
}

.submit_button button {
    width: 100%;
    border: none;
    color: white;
    padding: 12px 70px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.error_message {
    width: 70%;
    margin: 0px auto 30px auto;
}

.error_message p {
    text-align: center;
    color: rgba(255, 0, 0, 0.638);
}


@media only screen and (max-width: 1000px) {

    .design_image { 
        display: none;
    }

    .submit_button {
        width: 100%;
    }

    .error_message {
        width: 100%;
    }
    
}


