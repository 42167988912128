.check_icon {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 30px;
    height: 30px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

/* Next Previous Icon Buttons */
.prev_icon_btn, .next_icon_btn {
    position: absolute;
    top: 50%; 
    transform: translate(0%, -50%);
    background: none;
    background-image: linear-gradient(to right, #67DAF8, #0844CC);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}

.prev_icon_btn { left: 5px; }
.next_icon_btn { right: 5px; }

.find_times_button { 
    text-align: center; 
    margin: 60px 0px;
}

.find_times_button button {
    border: none;
    border-radius: 20px;
    background-image: linear-gradient(to right, #67DAF8, #0844CC);
    padding: 10px 40px;
    color: white;
    cursor: pointer;
}
                