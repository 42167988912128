.invoice_list {
    width: 100%;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 8px;
    padding: 25px;
}

.invoice_list_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.invoice_list_header button {
    background: none;
    padding: 10px 20px;
    border: 1px solid rgba(0, 0, 0, 0.13);
    border-radius: 10px;
}

.invoice_list_content {
    margin-bottom: 5px;
}

.download_all_btn {
    text-align: right;
}

.download_all_btn button {
    background: none;
    color: #3699FF;
    text-decoration: underline;
}


.invoice_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.invoice_info:last-child {
    border-bottom: none;
}

.invoice_date {
    margin-bottom: 5px;
}

.invoice_number {
    opacity: 0.5;
}
