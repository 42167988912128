.delete_prompt {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 4;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.625);
    opacity: 0.8;
}

.delete_prompt p {
    color: rgba(0, 0, 0, 0.53);
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.delete_prompt span {
    color: #FFAAA5;
}

.confirm_button {
    cursor: pointer;
    background-color: #FFAAA5;
    padding: 10px 20px;
    border-radius: 10px;
    color: white;
    font-weight: bold
}

.close_btn {
    background: none;
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 1.5rem;
    color: #FFAAA5;
}

.delete_prompt_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    overflow: auto;
    padding: 50px 100px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    text-align: center;

}

@media(max-width: 1200px) {

    .delete_prompt_content {
        width: 95%;
        padding: 50px 0px;
    
    }

}