.change_plan {
    width: 30%;
    height: 220px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.12));
    border-radius: 16px;
    text-align: center;
    padding: 30px;
    margin-bottom: 20px;
}

.trial_period_end_soon {
    margin-top: 15px;
    color: orangered;
}

.p_header {
    color: rgba(0, 0, 0, 0.53);
    font-size: 1.2rem;
    margin-bottom: 6px;
}


.h3_header {
    color: rgba(0, 0, 0, 0.65);
    font-size: 1.5rem;
    margin-bottom: 6px;
}

.cancel_btn_wrapper {
    margin-top: 20px;
}

@media(max-width: 1200px) {
    .change_plan {
        width: 100%;
        order: 3;
    }
}