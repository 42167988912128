.select_stylist {
    width: 80%;
    margin: 0px auto 50px auto;
    max-height: 400px;
    position: relative;
    padding: 0% 5%;
}

.select_stylist_card_wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0% 2%; /* Will act as margin between divs with the className stylist */
    transition: 1s;
}

.select_stylist_card {
    position: relative;
    width: 50%;
    height: 350px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}

.select_stylist_card_content {
    width: 100%;
    margin: auto;
}

.stylist_image_wrapper {
    margin: 20px auto;
    text-align: center;
}

.stylist_image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.select_stylist_card .appointment_info p {
    margin-bottom: 10px;
    font-size: 0.85rem;
    font-weight: bold;
}

.select_stylist_card .appointment_info .price_text {
    font-size: 1.5rem;
    font-weight: bolder;
    color: darkblue;
}

.select_stylist_card .appointment_info .price_text sup {
    font-size: 16px;
    margin-left: 2px;
}



/* Responsive Design */
@media(max-width: 480px) {.select_stylist {width: 95%;}}
@media((min-width: 480px) and (max-width: 600px)) {.select_stylist {width: 75%;}}
@media((min-width: 600px) and (max-width: 800px)) {.select_stylist {width: 70%;}}
@media((min-width: 800px) and (max-width: 1000px)) {.select_stylist {width: 95%;}}
@media((min-width: 1000px) and (max-width: 1200px)) {.select_stylist {width: 85%;}}

@media(max-width: 800px) {
    .select_stylist {
        padding: 0%;
    }
    .select_stylist_card {
        width: 100%;
    }
    .select_stylist_card_wrapper {
        min-width: 100%;
        padding: 0% 4%;
    }
}

@media((min-width: 800px) and (max-width: 1200px)) {
    .select_stylist {
        padding: 0% 6%;
    }
    .select_stylist_card_wrapper {
        min-width: 50%;
        padding: 0% 4%;
    }
}

@media((min-width: 1200px) and (max-width: 1400px)) {
    .select_stylist {
        width: 95%;
        padding: 0% 5%;
    }
}
  
  
  
  
  