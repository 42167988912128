.save_button {
    text-align: center;
    margin-top: 30px;
}

.save_button button,
.add_to_po_btn {
    padding: 7px 50px;
    border-radius: 5px;
    background-color:#FFAAA5;
    color: white;
}

.add_to_po_btn {
    margin-top: 10px;
}

.full_width_save_btn button {
    width: 100%;
}

.save_button button:disabled {
    background-color: gray;
}
