html, body {
  height: 100vh;
}

#root {
  height: 100vh;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'DM Sans', sans-serif;
}

.App {
  height: 100vh;
  display: flex;
  gap: 30px;
  overflow: auto;
}

