.gift_card_table {
    background-color: #FFFFFF;
    min-height: 600px;
    border-radius: 20px;
    padding: 20px;
}

.table_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.gift_card_table table {
    width: 95%;
    margin: auto;
    border-collapse: collapse;
}

.gift_card_table table th {
    text-align: left;
    color: #809FB8;
    font-size: 1.1rem;
    padding-bottom: 20px;
}

.gift_card_table table td {
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.113);
    padding: 20px 0px;
    font-size: 1.1rem;
}

.action_buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.action_buttons button {
    margin-right: 20px;
    background: none;
}

.action_buttons button:last-child {
    margin-right: 0px;
}

.responsive_gift_card_item {
    border: 1px solid rgba(0, 0, 0, 0.103);
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 10px;
    margin-bottom: 20px;
}

.responsive_gift_card_code {
    color: rgba(0, 0, 255, 0.552);
}

.responsive_code_actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.103);
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.responsive_other_info p {
    margin-bottom: 5px;
}

@media (max-width: 1200px) {

    .gift_card_table {
        padding: 20px 0px;
    }

    .gift_card_table table {
        width: 100%;
    }

    .table_header {
        flex-direction: column;
        align-items: flex-start;
    }

    .table_header h2 {
        margin-bottom: 10px;
    }

}
