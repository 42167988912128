.add_appointment_modal {
    padding: 40px 20px;
}

.validation_error_message {
    font-size: 0.9rem;
    color: #FF6363;
}

/* Appointment Type */
.appointment_type { 
    text-align: center; 
    padding-bottom: 15px;
    margin-bottom: 20px;
}

.appointment_type button, .date_type_btn {
    background-color: white;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.205), 0 6px 20px 2px rgba(0, 0, 0, 0.199);
    border: none;
    border-radius: 20px;
    padding: 6px 20px;
    cursor: pointer;
}

.appointment_type button:first-child { margin-right: 20px; }


.select_date {
    width: 50%;
    margin: 0px auto 10px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 4px;
}

.select_date input[type=text] {
    width: 245px;
    border: 1px solid rgba(0, 0, 0, 0.222);
    background-color: white;
}

.select_date input[type=text]:focus {
    outline: none;
}

.select_date p.select_date_label {
    font-weight: bold;
    margin-bottom: 5px;
}

.select_date p.select_date_warning_message {
    font-size: 0.9rem;
    color: #FF6363;
}

.select_date_datepicker {
    z-index: 10;
}

.select_date_datepicker input {
    background-color: #E7F6F2;
    width: 100%;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 5px;
}

/* Select Time */
.select_time {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
}

.select_time > * {
    width: 50%;
}


.regular_appointment {
    border: 1px solid rgba(0, 0, 0, 0.127);
    margin-bottom: 20px;
}

.select_location, .select_category, .select_treatment, .select_stylist {
    width: 85%;
    margin: 20px auto;
}


.payment_method_container {
    width: 85%;
    margin: auto;
}




/* Client Info And Redeem Coupon */
.client_info, .redeem_coupon {
    width: 90%;
    padding: 2%;
    margin: 20px auto 0px auto;
}

.client_info h3, .redeem_coupon h3 {
    width: 50%;
    margin: 0px auto 20px auto;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.133);
    padding-bottom: 3px;
}

.client_input_container {
    position: relative;
}

.client_list {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    background-color: white;
    border-radius: 5px;
    width: 100%;
    max-height: 200px;
    overflow: auto;
    padding: 4% 2%;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.205), 0 6px 20px 2px rgba(0, 0, 0, 0.199);
}

.client_list ul { 
    list-style: none; 
    padding: 0px 2px;
}

.client_list ul li {
    background-color: white;
    border-radius: 5px;
    padding: 7px 5px;
    margin-bottom: 10px;
    transition: 0.2s;
    cursor: pointer;
}

.client_list ul li:last-child {
    margin-bottom: 0px;
}

.client_list ul li:hover {
    background-color: #E4E0E1;
}

.redeem_coupon_button {
    text-align: center;
    margin-bottom: 10px;
}

.redeem_coupon_button button {
    background-image: linear-gradient(to right, #AF7AB3, #80558C, #AF7AB3 );
    border: none;
    border-radius: 20px;
    padding: 4px 30px;
    cursor: pointer;
    color: white;
}

.redeem_coupon_button button:disabled {
    background-image: linear-gradient(to right, lightgrey, rgba(0, 0, 0, 0.288), lightgrey );
}

.edit_client_info h2 {
    text-align: center;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.097);
}

.client_info_update_button {
    text-align: center;
    margin-top: 25px;
}

.client_info_update_button button {
    background-image: linear-gradient(to right, #67DAF8, #0844CC);
    border: none;
    border-radius: 20px;
    cursor: pointer;
    color: white;
    padding: 7px 30px;
}


.appointment_date_list {
    background-color: #e7f6f297;
    border: 1px solid rgba(0, 0, 0, 0.209);
    border-radius: 5px;
    padding: 20px;
    margin-top: 5px;
    margin-bottom: 20px;
}

.date_type_buttons {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.date_type_btn_selected {
    background-color: #002B5B;
    color: white;
}

.select_start_end_date {
    display: flex;
    gap: 20px;
}

.selected_date_list {
    margin-top: 10px;
}

.selected_date {
    width: 245px;
    background-color: #F6F5F5;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    margin-bottom: 10px;
}

.selected_date:last-child {
    margin-bottom: 0px;
}

.selected_date button {
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

/* Save Button */
.save_button {
    text-align: center;
    margin-top: 30px;
}

.save_button button {
    background-color: #FFAAA5;
    border: none;
    border-radius: 10px;
    padding: 10px 50px;
    cursor: pointer;
    color: white;
}




@media(max-width: 1000px) {
    .select_date { width: 100%; }
    .select_date input[type=text] {
        width: 282px;
    }
    .select_time { display: block; }
    .select_time > * {
        width: 100%;
    }
    .time_block_appointment_title { width: 80%; }
    .select_category, .select_treatment { margin-bottom: 20px; }
    .client_info h3, .redeem_coupon h3 { width: 100%; }

    .date_type_buttons, 
    .select_start_end_date {
        flex-direction: column;
    }

    .select_date:nth-child(1) input[type=text],
    .select_date:nth-child(2) input[type=text] {
        width: 240px;
    }

}