.next_payment {
    width: 100%;
    background-color: white;
    filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.12));
    border-radius: 16px;
    margin-bottom: 20px;
}

.next_payment_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3% 7%;
}

.next_payment h4 {
    opacity: 0.5;
    margin-bottom: 5px;
}

.pay_amount {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 2px;
}

.pay_date {
    opacity: 0.5;
    font-size: 0.8rem;
}

.card_icon {
    color: #A8DDE6;
    width: 40px;
    height: 40px;
}

.payment_method_btn {
    background-color: #FFAAA5;
    color: white;
    display: inline-block;
    width: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 10px 0px;
}

.val_err_msg {
    color: orangered;
    opacity: 0.8;
    font-size: 0.9rem;
    text-align: center;
    margin-top: 20px;
}

.button_div {
    text-align: center;
    margin: 35px 0px 20px 0px;
}