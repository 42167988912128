.responsive_appointment_item {
    border: 1px solid rgba(0, 0, 0, 0.10);
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 15px;
}

.responsive_appointment_item p {
    font-size: 0.9rem;
}

.item_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
}

.item_content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.item_header p,
.item_content p {
    font-weight: bold;
}

.item_header p:first-child {
    color: #A8DDE6;
}

.item_header p:last-child {
    color: #FFAAA5;
}

.other_info {
    opacity: 0.5;
    margin-bottom: 15px;
}

.other_info:last-child {
    margin-bottom: 0px;
}