.calendar_header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px 20px;
    margin-bottom: 5px;
    position: sticky;
    top: 0;
    z-index: 2;
}

.date_navigation,
.view_navigation {
    display: flex;
    justify-content: center;
    align-items: center;
}

.view_date_info {
    padding: 0px 40px;
    text-align: center;
}

.view_date_info p {
    font-size: 1.2rem;
    font-weight: bold;
}

.date_navigation button,
.view_navigation button {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 20px;
    padding: 7px 20px;
    margin-right: 20px;
    font-size: 0.8rem;
}

.date_navigation button:last-child,
.view_navigation button:last-child {
    margin-right: 0px;
}


@media(max-width: 1000px) {

    .calendar_header {
       flex-direction: column;
    }

    .date_navigation,
    .view_date_info {
        margin-bottom: 20px;
    }

    .date_navigation button,
    .view_navigation button {
        padding: 10px;
        font-size: 0.7rem;
    }

}