/* Input field with label and error message */
.input_field { 
    width: 100%; 
    margin-bottom: 20px;
}
.input_field label { 
    font-weight: bold;
}
.input_field .input { margin: 5px 0px 5px 0px; }

.input_field .input input[type=text], 
.input_field .input input[type=number], 
.input_field .input input[type=email], 
.input_field .input input[type=password] {
    width: 100%;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.222);
    border-radius: 4px;
    outline: none;
}

.input_field p {
    color: #FF6363;
    font-size: 0.9rem;
}


/* For Edit User Info Modal */
.user_info_input, 
.user_phone_input {
    margin-bottom: 0px;
}

.user_info_input label, 
.user_phone_input label {
    display: block;
    margin-bottom: 8px;
    opacity: 0.7;
    font-weight: normal;
}

.user_info_input .input, 
.user_phone_input .input {
    margin: 0px;
}

.user_info_input .input input[type=text],
.user_info_input .input input[type=email] {
    background-color: white;
    opacity: 0.7;
    font-size: 1.1rem;
}

/* Checkbox Input Field */
.checkbox_input_field input[type=checkbox] {
    margin-right: 10px;
}
