.add_stylist_container { margin-top: 30px; }

.add_stylist_container h2 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    color: rgba(0, 0, 0, 0.53);
    margin-bottom: 20px;
    padding-bottom: 5px;
    text-align: center;
}

.add_stylist {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.add_stylist > * {
    width: 50%;
    padding: 1% 5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.stylist_item {
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 3px 10px;
}

.stylist_item p { 
    margin-right: 30px; 
    color: rgba(0, 0, 0, 0.53);
}

.stylist_item button {
    background: none;
    border: none;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.53);
    padding: 0px 10px;
    font-size: 1.3rem;
    transition: 0.5s;
}

.stylist_item button:hover { background-color: aliceblue; }
