.wait_list_table {
    background-color: #FFFFFF;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    height: 600px;
    overflow: auto;
    border-radius: 10px;
    padding: 40px 20px;
}

.wait_list_table table {
    width: 100%;
    border-collapse: collapse;
}

.wait_list_table table th {
    text-align: left;
    color: #809FB8;
}

.wait_list_table table td {
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.113);
    padding: 15px 0px;
    margin-right: 20px;
}

.treatment_stylist_list {
    list-style: none;
}

.treatment_stylist_list li {
    font-size: 0.9rem;
}

.action_buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.action_buttons button {
    margin-right: 20px;
    background: none;
}

.action_buttons button:last-child {
    margin-right: 0px;
}

.responsive_wait_list_item {
    border: 1px solid rgba(0, 0, 0, 0.103);
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 10px;
    margin-bottom: 20px;
}

.responsive_title_actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.103);
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.responsive_title_actions p {
    color: rgba(0, 0, 255, 0.552);
}

.responsive_client_email {
    margin-bottom: 10px;
}

@media (max-width: 1200px) {

    .wait_list_table {
        padding: 20px;
    }

}
