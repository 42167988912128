.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 4;
}

.modal_background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.842);
    opacity: 0.8;
}

.modal_cls_btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.53);
    font-size: 1.2rem
}

.modal_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
    min-width: 40%;
    min-height: 40%;
    max-width: 95%;
    max-height: 95%;
    overflow: auto;
    background-color: #FFFFFF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
}

@media(max-width: 1000px) {

    .modal_cls_btn {
        top: 10px;
        right: 10px;
        font-size: 0.9rem
    }

    .modal_content {
        min-width: 90%;
        min-height: 90%;
    }

}