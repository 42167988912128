.treatments_page {
    position: relative;
}

.search_add_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.add_edit_treatment {
    padding: 20px 60px;
}

.add_edit_treatment h1 {
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.53);
}

.val_error_msg {
    color: orangered;
    font-size: 0.9rem;
    font-weight: bold;
    margin-top: 5px;
    text-align: center;
}

.save_btn_wrapper {
    text-align: center;
    margin-top: 25px;
}


@media(max-width: 1000px) {
    .add_edit_treatment {
        padding: 20px 0px;
    }
    .search_add_btn {
        flex-direction: column;
    }
}