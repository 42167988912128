button {
    border: none;
    cursor: pointer;
}

.add_btn, .save_btn {
    background-color: #FFAAA5;
    border-radius: 6px;
    color: white;
    font-size: 1rem;
}

.add_btn {
    padding: 14px 25px;
}

.save_btn {
    padding: 14px 50px;
}

@media(max-width: 1200px) {
    .add_btn {
        display: inline-block;
        width: 100%;
    }
}
