.buy_package {
    padding: 30px;
}

.buy_package h2 {
    margin-bottom: 15px;
    color: rgba(0, 0, 0, 0.53);
}

.client_input_container {
    position: relative;
}

.client_list {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    background-color: white;
    border-radius: 5px;
    width: 100%;
    max-height: 200px;
    overflow: auto;
    padding: 10px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.205), 0 6px 20px 2px rgba(0, 0, 0, 0.199);
}

.client_list ul { 
    list-style: none; 
    padding: 0px 2px;
}

.client_list ul li {
    background-color: white;
    border-radius: 5px;
    padding: 7px 5px;
    margin-bottom: 10px;
    transition: 0.2s;
    cursor: pointer;
}

.client_list ul li:last-child {
    margin-bottom: 0px;
}

.client_list ul li:hover {
    background-color: #E4E0E1;
}

.dropdown_container {
    margin-top: 20px;
    margin-bottom: 20px;
}

.dropdown_container p {
    color: rgba(0, 0, 0, 0.53);
    margin-bottom: 5px;
}

.installment_instruction {
    color: rgba(255, 68, 0, 0.776);
    margin-bottom: 7px;
    font-size: 0.9rem;
}

.add_installment_container {
    border: 1px solid rgba(0, 0, 0, 0.224);
    border-radius: 5px;
    padding: 20px;
}

.add_installment {
    background-color: #ecebde64;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
}

.add_installment > * {
    width: 180px;
}

.select_date {
    margin-bottom: 20px;
}

.select_date label {
    display: block;
    margin-bottom: 5px;
    color: rgba(0, 0, 0, 0.53);
}

.select_date label span {
    color: #FF6363;
}

.select_date input[type=text] {
    width: 180px;
    border: 1px solid rgba(0, 0, 0, 0.222);
    border-radius: 5px;
    padding: 12px 15px;
    margin-bottom: 5px;
}

.select_date input[type=text]:focus {
    outline: none;
}

.add_installment_button {
    width: 150px;
    display: flex;
    align-items: center;
}

.add_installment_button button {
    display: inline-block;
    width: 100%;
    border-radius: 5px;
    padding: 14px 0px;
}

.installment_table {
    background-color: #ecebde64;
    border-radius: 5px;
    margin: 20px 0px;
    padding: 10px 5px;
}

.installment_table table {
    width: 100%;
    overflow: auto;   
    background-color: rgba(221, 221, 221, 0.062);
    border-collapse: collapse;
}

.installment_table table td {
    position: relative;
    text-align: center;
    padding: 7px 0px;
    font-size: 0.8rem;
}

.installment_table table thead tr { 
    border-bottom: 1px solid rgba(0, 0, 0, 0.096); 
}

.installment_table table thead tr th { 
    padding-bottom: 3px; 
}



.save_btn_wrapper {
    text-align: center;
    margin-top: 20px;
}

@media(max-width: 600px) {

    .buy_package {
        padding: 30px 20px;
    }

    .add_installment {
        gap: 0px;
    }
    
    .add_installment > * {
        width: 450px;
    }

    .select_date input[type=text] {
        width: 255px;
    }

    .add_installment_button button {
        width: 255px;
    }

    .installment_table table thead tr th { 
        font-size: 0.75rem;
    }
    
    
}