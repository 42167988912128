.wait_list h2 {
    margin-bottom: 10px;
}

.wait_list_details {
    padding: 40px;
    overflow: auto;
}

.wait_list_item_info {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.136);
    padding: 10px 0px;
}

.wait_list_item_info p {
    white-space: pre-wrap;
    word-break: break-all;
}

.wait_list_item_info p:first-child {
    width: 42%;
}

.wait_list_item_info p:last-child {
    width: 58%;
}

.wait_list_treatment_stylist_list {
    margin-top: 40px;
}

.wait_list_treatment_stylist_list table {
    border-collapse: collapse;
}

.wait_list_treatment_stylist_list table td {
    padding: 30px 30px 10px 0px;
    font-size: 0.8rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.136);
    color: #050708;
}


@media(max-width: 1000px) {
    .wait_list_details {
        padding: 40px 10px;
        overflow: auto;
    }
    .wait_list h2 {
        margin-left: 20px;
    }

    .wait_list_item_info p {
        font-size: 0.8rem;
    }
}