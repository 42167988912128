.action_message {
    position: fixed;
    top: 15%;
    left: 50%;
    transform: translate(-50%);
    z-index: 10;
    background-color: #256D85;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 8px;
    color: white; 
    display: flex;
    gap: 40px;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.action_message button {
    background: none;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 1.2rem;
}

.validation_error_msg {
    text-align: center;
    color: orangered;
    margin: 15px 0px;
    font-size: 0.9rem;
    font-weight: bold;
}


@media(max-width: 1000px) {
    .action_message { width: 90%; }
    .action_message p { font-size: 0.8rem;}
}
