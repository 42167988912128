.select_date {
  position: relative;
  width: 100%;
  margin: auto;
  padding: 0% 5%;
}
.available_dates {
  position: relative;
  width: 100%;
  height: 250px;
  margin: auto;
  padding: 2% 5%;
}
.available_date_list {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media(max-width: 1200px) { .select_date { padding: 0% 12%; } }

@media(max-width: 800px) { .available_dates { height: 150px; } }
@media((min-width: 801px) and (max-width: 1600px)) { .available_dates { height: 250px; }}
