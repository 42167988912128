.login {
    width: 100%;
    display: flex;
    background-color: white;
    max-height: 1000px;
}

.user_credentials {
    width: 50%;
    height: 100%;
    overflow: auto;
    padding: 5%;
}

.user_credentials > * {
    color: #67748E;
}

.user_credentials h2, 
.user_credentials p {
    text-align: center;
}

.user_credentials h2 {
    margin-bottom: 5px;
}

.user_credentials p {
    margin-bottom: 40px;
}


.login_form {
    width: 70%;
    margin: auto;
}

.forgot_password_section {
    width: 70%;
    margin: auto;
}

.forgot_password_section h2 {
    margin-bottom: 30px;
}

.forgot_password_button {
    text-align: right;
}

.forgot_password_button span
 {
    background: none;
    cursor: pointer;
    color: #A8DDE6;
    font-size: 0.85rem;
}


.no_account {
    width: 70%;
    margin: 30px auto 0px auto;
    display: flex;
    justify-content: center;
    gap: 5px;
}

.no_account > * {
    font-size: 0.85rem;
}

.no_account a {
    text-decoration: none;
    color: #A8DDE6;
}


@media(max-width: 1000px) {

    .login {
        padding: 2%;
    }

    .user_credentials {
        width: 100%;
    }

    .login_form {
        width: 100%;
    }

    .forgot_password_section {
        width: 100%;
    }
    
    .no_account {
        width: 100%;
        margin: 30px auto 30px auto;
    }
}