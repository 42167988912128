.packages_page {
    position: relative;
}

.search_add_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.add_edit_package {
    padding: 40px 30px;
}

.add_edit_package h1 {
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.53);
}

.validation_error_msg {
    text-align: center;
    margin: 20px 0px;
    font-weight: bold;
    color: orangered;
}

.save_btn_wrapper {
    text-align: center;
    margin-top: 20px;
}


@media(max-width: 1000px) {

    .add_edit_package {
        padding: 40px 10px;
    }

    .search_add_btn {
        flex-direction: column-reverse;
        gap: 20px;
        margin-bottom: 0px;
    }

}