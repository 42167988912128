.sub_plans {
    width: 67%;
    margin-right: 3%;
    background: #FFFFFF;
    filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.12));
    border-radius: 16px;
    padding: 30px 10px;
    margin-top: 20px;
}

.sub_plans h2 {
    padding-left: 10px;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.73)
}

.sub_plan_list {
    display: flex;
}

.sub_item_container {
    width: 33.33%;
    padding: 10px;
}

.sub_item {
    position: relative;
    min-height: 520px;
    background: #FFFFFF;
    border: 2px solid #A8DDE6;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 30px 10px;
}

.sub_item h3 {
    color: #A8DDE6;
    margin-bottom: 5px;
}

.sub_item p {
    margin-bottom: 20px;
    color: rgba(44, 62, 80, 0.8);
}

.sub_plan_prices {
    margin-bottom: 20px;
}

.sub_plan_prices ul {
    list-style: none;
    margin-bottom: 2px;
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.53);
}

.sub_feature_list {
    margin-bottom: 60px;
}

.sub_feature_list p {
    color: #A8DDE6;
    margin-bottom: 2px;
    font-size: 0.85rem;
}

.sub_feature_item {
    display: flex;
    align-items: flex-start;
}

.check_icon {
    margin-right: 15px;
}

.sub_item button {
    display: inline-block;
    width: 70%;
    position: absolute;
    left: 50%;
    bottom: 2%;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    cursor: pointer;
    color: white;
    padding: 15px 0px;
}

@media(max-width: 1200px) {
    .sub_plans {
        width: 100%;
        margin-bottom: 20px;
    }

    .sub_plan_list {
        display: block;
    }

    .sub_item_container {
        width: 100%;
    }
}
