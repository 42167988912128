.select_time {
  position: relative;
  width: 100%;
  margin: 0% auto;
  padding: 0% 5%;
}

.available_times { 
  width: 100%;
  background-color: #92a9bd33;
  border-radius: 20px;
}

.available_time_list {
  width: 70%;
  margin: auto;
  min-height: 300px;
  padding: 20px 0px;
}

.no_available_time {
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
  
.time {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.time_content {
  width: 20%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.discounted_price {
  position: absolute;
  right: 10%;
  top: 18%;
  font-size: 0.8rem;
  font-weight: bold;
  color: #ff5034;
}

.time_content_text {
  padding: 10px;
  font-size: 24px;
}

@media(max-width: 800px) { .available_time_list { width: 100%; } }
@media(max-width: 1000px) { .time_content_text { font-size: 20px; } }
@media(max-width: 1200px) { .select_time { padding: 0% 12%; } }

@media(max-width: 450px) { .time_content { width: 100%; }}
@media((min-width: 451px) and (max-width: 600px)) { .time {width: 50%; margin: auto} .time_content { width: 50%; } }
@media((min-width: 601px) and (max-width: 800px)) { .time { width: 75%; margin: auto} .time_content { width: 33.33%; } }
@media((min-width: 801px) and (max-width: 1200px)) { .time_content { width: 25%; } }
