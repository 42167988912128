.add_edit_user {
    padding: 20px 60px;
}

.add_edit_user h1 {
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.53);
}

.save_btn_wrapper {
    text-align: center;
    margin-top: 40px;
}


.select_buttons {
    text-align: center;
    margin-bottom: 50px;
}

.select_buttons button {
    background: none;
    font-size: 1.3rem;
    padding: 10px 30px;
}

.select_buttons button:first-child {
    margin-right: 10px;
}


.file_input_container {
    width: 300px;
    height: 200px;
    margin: 0px auto 30px auto;
    background-color: rgba(255, 170, 165, 0.1);
    border: 1px solid #A8DDE6;
    border-style: dashed;
    border-radius: 4px;
}

.access_types {
    display: flex;
    flex-wrap: wrap;
}

.access_group_item {
    width: 50%;
    padding-bottom: 20px;
}

.stylist_section {
    border: 1px solid rgba(0, 0, 0, 0.175);
    border-radius: 7px;
    padding: 10px;
    margin-top: 10px;
}


/* Work Schedule */
.work_schedule {
    width: 100%;
    margin: 10px auto 20px auto;
    position: relative;
    padding: 1% 0%;
}

.work_schedule h3 {
    width: 35%;
    margin: 0px auto 20px auto;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.096);
    text-align: center;
}

.work_schedule_content {
    display: flex;   
}

.dropdown_container {
    width: 25%;
    padding: 0% 1.5%;
}

p.dropdown_label {
    font-size: 0.8rem;
    font-weight: bold;
}

.add_workschedule_button {
    margin: 30px 0px;
    text-align: center;
}

.add_workschedule_button button {
    background-color: #4C3575;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    padding: 5px 30px;
}

.work_schedule_table {
    width: 90%;
    margin: auto;
    overflow: auto;   
    background-color: rgba(221, 221, 221, 0.062);
    border-collapse: collapse;
}

.work_schedule_table td {
    position: relative;
    text-align: center;
    padding: 7px 0px;
    font-size: 0.8rem;
}

.work_schedule_table thead tr { border-bottom: 1px solid rgba(0, 0, 0, 0.096); }
.work_schedule_table thead tr th { padding-bottom: 3px; }


@media(max-width: 1000px) {
    .add_edit_user {
        padding: 20px 0px;
    }
    .search_add_btn {
        flex-direction: column;
    }

    .select_buttons button {
        font-size: 1rem;
        padding: 10px;
    }

    .work_schedule {
        margin-top: 20px;
    }

    .work_schedule h3 {
        width: 95%;
    }

    .work_schedule_content {
        flex-direction: column;  
    }
    
    .dropdown_container {
        width: 100%;
        margin-bottom: 20px;
    }

    .work_schedule_table thead tr th { 
        font-size: 0.85rem;
    }

    .work_schedule_table td {
        font-size: 0.75rem;
    }
}

