.locations_page {
    padding: 15px 0px;
    position: relative;
}

.search_add_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.add_edit_location {
    padding: 20px 30px;
}

.add_edit_location h1 {
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.53);
}

.save_btn_wrapper {
    text-align: center;
    margin-top: 40px;
}

@media(max-width: 1200px) {
    .add_edit_location {
        padding: 20px 10px;
    }
    .search_add_btn {
        flex-direction: column-reverse;
        gap: 20px;
    }
}