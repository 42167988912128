.coupons_page {
    position: relative;
}

.search_add_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.add_edit_coupon h1 {
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.53);
}

.code_use_limit {
    background-color: #FFFFFF;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    align-items: center;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
}

.expiry_date {
    background-color: #FFFFFF;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
}

.expiry_date label {
    display: block;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.53);
}

.select_date_input {
    width: 100%;
}

.select_date_input input {
    width: 225px;
    padding: 13px;
    border: 1px solid rgba(0, 0, 0, 0.222);
    border-radius: 6px;
}

.select_date_input input:focus {
    outline: none;
}

.input_field_container:first-child {
    margin-right: 20px;
}

.save_btn_wrapper {
    text-align: center;
    margin-top: 20px;
}


@media(max-width: 1000px) {

    .add_edit_coupon {
        padding: 10px 0px;
    }

    .search_add_btn {
        flex-direction: column;
    }

    .code_use_limit {
        display: block;
    }

    .input_field_container:first-child {
        margin-right: 0px;
    }

    .select_date_input input {
        width: 275px;
        padding: 13px;
        border: 1px solid rgba(0, 0, 0, 0.222);
        border-radius: 6px;
    }
}