.copy_snippet_btn {
    position: fixed;
    left: 20px;
    bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 10px 25px;
    background-color: #FFAAA5;
    border-radius: 5px;
    color: white;
}

.snippet_modal {
    position: fixed;
    left: 20px;
    bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 900px;
    max-width: calc(100% - 40px);
    height: 80%;
    overflow: auto;
    background-color: #F5F7F8;
    border-radius: 10px;
    padding: 80px 30px;
}

.snippet_cls_btn {
    position: absolute;
    right: 20px;
    top: 10px;
    background: none;
    color: rgba(255, 0, 0, 0.442);
    font-size: 1.5rem;
    font-weight: bold;
}

.snippet_modal_item {
    margin-bottom: 40px;
    border: 1px solid rgba(0, 0, 0, 0.13);
    padding: 20px;
    border-radius: 10px;
}

.snippet_modal_item:last-child {
    margin-bottom: 0px;
}

.snippet_modal_item h3 {
    color: #67748E;
    margin-bottom: 5px;
    
}

.snippet_modal_item p {
    color: #67748E;
    margin-bottom: 5px;
}

.code_container {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    padding: 20px;
    font-size: 0.9rem;
    border-radius: 10px;
    overflow: auto;
}
