.date_card_wrapper {
  height: 250px;
  position: relative;
  left: 0;
  min-width: 20%;
  display: flex;
  align-items: center;
  padding: 0% 3.50%;
  transition: 0.8s;
}
    
.date_card {
  position: relative;
  background-color: white;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 16px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  height: 160px;
  text-align: center;
  color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
  
.date_card_content {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.clockIcon_container {
  position: absolute;
  left: 50%;
  bottom: -15%;
  transform: translate(-50%);
  width: 40px;
  height: 40px;
  z-index: 2;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(255, 0, 0, 0.192);
}

.responsive_date_card {
  position: relative;
  width: 60px;
  height: 60px;
  margin: auto;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.responsive_date_card p {
  font-size: 0.6rem;
  font-weight: bold;
}

/* For Responsive Date Card */
@media(max-width: 600px) { .date_card_wrapper { min-width: 33.33%; } }
@media((min-width: 601px) and (max-width: 800px)) { .date_card_wrapper { min-width: 25%; } }
@media(max-width: 800px) { .date_card_wrapper { height: 120px; } }

/* For Regular Date Card */
@media only screen and (max-width: 1200px) and (min-width: 801px) { .date_card_wrapper { min-width: 25%; } }

