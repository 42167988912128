.gift_card_page {
    position: relative;
}

.search_add_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.gift_card_details {
    padding: 40px;
    overflow: auto;
}

.gift_card_item_info {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.136);
    padding: 10px 0px;
}

.gift_card_item_info p {
    white-space: pre-wrap;
    word-break: break-all;
}

.gift_card_item_info p:first-child {
    width: 42%;
}

.gift_card_item_info p:last-child {
    width: 58%;
}

.card_modal {
    padding: 40px;
}

.card_modal_header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.172);
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 4px;
}

.payment_method_dropdown {
    width: 60%;
    margin: 30px auto 20px auto;
}

.buyer_info, .receiver_info { margin-bottom: 30px; }

.buyer_info h3, .receiver_info_header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.172);
    padding-bottom: 4px;
    font-style: italic;
    color: green;
}

.receiver_info_header span {
    margin-left: 5px;
    font-weight: normal;
    font-size: 0.85rem;
    color: green;
}

.email_phone {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.email_phone > * { width: 50%; }

.save_button {
    width: 100%;
    text-align: center;
}

.save_button button {
    width: 250px;
    border: none;
    border-radius: 5px;
    background-color: #FFAAA5;
    color: white;
    cursor: pointer;
    padding: 10px 25px;
}

/* Confirmation */
.confirmation {
    width: 100%;
    margin-top: 20px;
}

.confirmation h4 {
    text-align: center;
    margin-bottom: 5px;
}

.confirmation span { color: blue; }

.confirmation_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


@media(max-width: 1000px) {

    .card_modal {
        padding: 40px 20px;
    }

    .payment_method_dropdown {
        width: 100%;
    }

    .email_phone {
        flex-direction: column;
    }
    .email_phone > * { width: 100%; }

    .search_add_btn {
        flex-direction: column;
    }

    .gift_card_details {
        padding: 40px 10px;
        overflow: auto;
    }
 
    .gift_card_item_info p {
        font-size: 0.8rem;
    }

    .confirmation h4 { font-size: 0.8rem; }

}