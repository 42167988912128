.change_treatment {
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.error_msg {
    color: darkorange;
    text-align: center;
}

.save_button {
    margin-top: 10px;
    text-align: center;
}

.save_button button {
    background-color: #FFAAA5;
    border: none;
    border-radius: 10px;
    padding: 10px 50px;
    cursor: pointer;
    color: white;
}

.save_button button:disabled {
    background-color: lightgray;
}