.purchase_order {
    padding: 40px;
}

.purchase_order h2 {
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.53);
}

.purchase_order_products table {
    width: 100%;
    border-collapse: collapse;
}

.purchase_order_products table th {
    text-align: left;
    color: #809FB8;
    font-size: 1.1rem;
    padding: 10px 0px;
}

.purchase_order_products table td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.113);
    padding: 10px 0px;
    font-size: 0.9rem;
}

.purchase_order_products table td button {
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: #FFAAA5;
}

.purchase_order_success h3 {
    margin-bottom: 20px;
}

.purchase_order_success button {
    padding: 7px 50px;
    border-radius: 5px;
    background-color:#FFAAA5;
    color: white;
}

@media(max-width: 1000px) {

    .purchase_order {
        padding: 20px 10px;
    }

    .purchase_order_products table th {
        font-size: 0.8rem;
        padding: 5px 0px;
    }

    .purchase_order_products table td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.113);
        padding: 5px 0px;
        font-size: 0.7rem;
    }
    
}