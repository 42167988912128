.time_discounts {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 10px;
}

.time_discounts_content { 
    padding-right: 20px;
    width: 100%; 
    overflow: auto; 
}

.toggle_discount_status {
    padding: 30px 20px;
}

.toggle_discount_status h2 {
    margin-bottom: 20px;
}

.toggle_discount_status button {
    display: block;
    width: 150px;
    border-radius: 5px;
    color: white;
    background-color: rgb(255, 170, 165);
    padding: 10px 0px;
    margin: 50px auto 0px auto;
}

.add_discount {
    padding: 40px 20px;
}

.add_discount_button {
    text-align: right;
    margin-bottom: 20px;
}

.add_discount_button button {
    background-color: rgb(255, 170, 165);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    padding: 10px 20px;
}

/* Header */
.header {
    font-size: 1.2rem;
    color: darkblue;
    margin-bottom: 5px;
}

/* Checkbox Input Field */
.checkbox_input {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.checkbox_input_value {
    font-size: 1.1rem;
    font-weight: bold;
    margin-left: 8px;
    color: darkblue;
}

/* Select Location */
.select_location {
    margin-bottom: 20px;
}

/* Select Stylist */
.select_stylist {
    margin-bottom: 20px;
}

/* Last Minute Discount */
.last_minute_discount {
    margin-bottom: 20px;
}

.last_minute_discount_content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.percentage_hours {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.last_minute_discount_content input[type=text] {
    width: 50px;
    text-align: center;
}

.last_minute_discount_content input[type=text]:focus {
    outline: none;
}

/* Off Peak Discounts */
.offpeak_discounts {
    width: 100%;
    margin-bottom: 20px;
}

.offpeak_discount_table {
    width: 100%;
}

.offpeak_discount_table table {
    width: 100%;
}
.offpeak_discount_table table th,
.offpeak_discount_table table td {
    height: 45px;
    border: 1px solid black;
    padding: 5px;
    font-size: 0.8rem;
}

.offpeak_discount_table table td:first-child {
    width: 20%;
}

.offpeak_discount_table table {
    border-collapse: collapse;
}

.offpeak_discount_table table input[type=text] {
    width: 100%;
    height: 100%;
    border: none;
    text-align: center;
}

.offpeak_discount_table table input[type=text]:focus {
    outline: none;
}

.save_close_buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;
    text-align: center;
}

.save_close_buttons button {
    border-radius: 5px;
    color: white;
    background-color: rgb(255, 170, 165);
    padding: 7px 30px;
}

@media(max-width: 800px) {

    .time_discounts {
        padding: 10px 0%;
    }

    .last_minute_discount_content {
        flex-direction: column;
        align-items: flex-start;
    }

    .offpeak_discount_table table th,
    .offpeak_discount_table table td {
        font-size: 0.7rem;
    }

    .offpeak_discount_table table input[type=text] {
       font-size: 0.7rem;
    }

    .add_discount_button {
        text-align: left;
    }
    
    .add_discount_button button {
        display: inline-block;
        width: 100%;
    }
    
}