.add_edit_product {
    padding: 40px 50px;
    overflow: auto;
}

.add_edit_product h2 {
    margin-bottom: 40px;
}

.file_input_container {
    margin: 20px 0px;
}

.price_stock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

.select_category_brand {
    position: relative;
}

.category_brand_list {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 3;
    width: 100%;
    min-height: 200px;
    max-height: 400px;
    overflow: auto;
    background-color: rgba(255, 255, 255, 0.967);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
}

.category_brand_list ul {
    list-style: none;
    padding: 20px 10px;
}

.category_brand_list ul li {
    cursor: pointer;
    background-color: #FAF6F0;
    border-radius: 4px;
    margin-bottom: 20px;
    transition: 0.5s;
    padding: 5px;
}

.category_brand_list ul li:last-child {
    margin-bottom: 0px;
}

.category_brand_list ul li:hover {
    background-color: #aebdca3c;
}

@media(max-width: 1000px) {

    .add_edit_product {
        padding: 20px;
    }

    .price_stock {
       flex-direction: column;
       gap: 0px;
    }
    
}