.coupon_table {
    background-color: #FFFFFF;
    min-height: 600px;
    border-radius: 20px;
    padding: 20px;
}

.table_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.coupon_table table {
    width: 95%;
    margin: auto;
    border-collapse: collapse;
}

.coupon_table table th {
    text-align: left;
    color: #809FB8;
    font-size: 1.1rem;
    padding-bottom: 20px;
}

.coupon_table table td {
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.113);
    padding: 20px 0px;
    font-size: 1.1rem;
}

.maximum_use_limit {
    display: inline-block;
    width: 140px;
    text-align: center;
    background-color: #A8DDE6;
    padding: 5px 0px;
    border-radius: 10px;
    color: white;
}

.action_buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.action_buttons button {
    margin-right: 20px;
    background: none;
}

.action_buttons button:last-child {
    margin-right: 0px;
}

.responsive_coupon_item {
    border: 1px solid rgba(0, 0, 0, 0.103);
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 10px;
    margin-bottom: 20px;
}

.responsive_coupon_code {
    color: rgba(0, 0, 255, 0.552);
}

.responsive_coupon_limit {
    margin-bottom: 10px;
}

.responsive_code_actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.103);
    padding-bottom: 10px;
    margin-bottom: 10px;
}

@media (max-width: 1200px) {
    
    .coupon_table {
        padding: 20px 0px;
    }
    .table_header {
        flex-direction: column;
        align-items: flex-start;
    }

    .table_header h2 {
        margin-bottom: 10px;
    }
}
