.product_buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-bottom: 25px;
}

.product_buttons button {
    background-color: white;
    border: 1px solid orange;
    padding: 12px 30px;
    border-radius: 10px;
    color: #FFAAA5;
}

.product_buttons button:first-child {
    background-color:#FFAAA5;
    color: white;
}

.product_buttons button:nth-child(2) {
    position: relative;
}

.product_buttons button:nth-child(2) span {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    background-color: #344C64;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search_container {
    margin-bottom: 25px;
}

@media(max-width: 1000px) {

    .product_buttons {
        flex-direction: column;
        gap: 25px;
    }

    .product_buttons button {
        width: 100%;
    }
    
}
