.policies {
    width: 100%;
    height: 100%;
    padding: 20px 0px;
}

.add_button {
    text-align: right;
    margin-bottom: 20px;
}

.add_button button {
    border-radius: 8px;
    padding: 10px 20px;
    background-color: #FFAAA5;
    color: white;
}

.add_button button:disabled {
    background-color: lightgray;
}

.create_policy_modal {
    padding: 40px;
}

.create_policy_modal h2,
.create_policy_modal p {
    color: rgba(0, 0, 0, 0.53);
    margin-bottom: 10px;
}

.create_policy_modal p {
    margin-bottom: 20px;
}

.new_content_item {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.new_content_item > * {
    max-width: 200px;
}

.new_content_item button {
    display: inline-block;
    width: 120px;
    height: 42px;
    background-color: #FFAAA5;
    border-radius: 5px;
    color: white;
}

.content_list_table {
    width: 70%;
    overflow: auto;   
    background-color: rgba(221, 221, 221, 0.062);
    border-collapse: collapse;
}

.content_list_table td {
    position: relative;
    padding: 10px 0px;
    font-size: 0.8rem;
}

.content_list_table thead tr { 

    border-bottom: 1px solid rgba(0, 0, 0, 0.096); 
}
.content_list_table thead tr th { 
    text-align: left;
    padding-bottom: 5px; 
}


.save_button {
    margin-top: 30px;
    text-align: center;
}

.save_button button {
    background-color: #FFAAA5;
    border-radius: 5px;
    color: white;
    padding: 10px 40px;
}

@media(max-width: 800px) {
    .add_button {
        text-align: center;
        margin-bottom: 20px;
    }
    .create_policy_modal {
        padding: 40px 10px;
    }
    .content_list_table {
        width: 100%;
    }
}

