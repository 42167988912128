.package_highlights {
    max-height: 380px;
    overflow: auto;
    background-color: #FFFFFF;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: 20px;
}

.highlight_input {
    margin-bottom: 20px;
}

.highlight_input_button {
    text-align: right;
}

.highlight_input_button button {
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    color: white;
}

.highlight {
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 10px;
    margin-bottom: 15px;
}

.highlight:last-child {
    margin-bottom: 0px;
}

.highlight p {
    white-space: pre-wrap;
    color: rgba(0, 0, 0, 0.53);
}

.highlight button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    background: none;
}