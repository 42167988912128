.tenant_profile_content {
    display: flex;
    flex-wrap: wrap;
}

.next_payment_invoice {
    width: 30%;
    margin-top: 20px;
}

@media(max-width: 1200px) {
    .next_payment_invoice {
        width: 100%;
        order: 4;
        margin-top: 0px;
    }
}